try { // Import webpack jQ
  window.$ = window.jQuery = require('jquery')
} catch (e) {}

require('./img-zoomer') // Import external files

$(function(){
  function sizeChanger(){
    if($(window).width()>1024) {
      $('#menu, #primary-nav, #primary-nav ul').attr('style', '')
      $('.rwd-arrow').removeClass('active')
    }
  }

  $(function(){
    sizeChanger()

    $("#primary-nav li.menuparent").append("<div class='rwd-arrow'></div>")

    $('.menurwd').click(function(e) {
      e.stopPropagation()
      $(this).parent().find('#primary-nav').slideToggle("slow")
      $(this).toggleClass('opened')
    })

    $('.rwd-arrow').click(function(e) {
      e.stopPropagation()
      if($(this).hasClass('active')){
        $(this).removeClass('active').parent().find('ul').first().slideUp("slow")
      }	else {
        $(this).addClass('active').parent().find('ul').first().slideDown("slow")
      }
    })
  })

  $(window).resize(function(){
    sizeChanger()
  })
})
