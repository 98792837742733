
// Lightbox for zoom images

function isLast (div) {
  var myPopup = div.parents('.img-zoomer').find('.img-zoomer-full')
  if (div.next().length <= 0) {
    myPopup.find('.img-zoomer-next').addClass('inactive')
  } else {
    myPopup.find('.img-zoomer-next').removeClass('inactive')
  }
  if (div.prev().length <= 0) {
    myPopup.find('.img-zoomer-prev').addClass('inactive')
  } else {
    myPopup.find('.img-zoomer-prev').removeClass('inactive')
  }
}

$('.img-zoomer .img-zoomer-zoomin').on('click', function(){
  var myPopup = $(this).parents('.img-zoomer').find('.img-zoomer-full'),
      myPic = $(this).parents('.img-zoomer-box').find('img').attr('src')
  myPopup.find('img').attr('src', myPic)

  isLast($(this).parents('.img-zoomer-box'))

  myPopup.fadeIn()
  $('body, html').css('overflow', 'hidden')
  $(this).parents('.img-zoomer-box').addClass('active')
})

$('.img-zoomer .img-zoomer-zoomout').on('click', function(){
  $(this).parents('.img-zoomer').find('.img-zoomer-full').fadeOut()
  $('body, html').css('overflow', 'visible')
  $('.img-zoomer-box').removeClass('active')
})

$('.img-zoomer-full-inner').on('click', function(e){
  if (e.target !== this) {
    return
  }
  $(this).parents('.img-zoomer-full').fadeOut()
  $('body, html').css('overflow', 'visible')
})

$('.img-zoomer-prev').click(function(){
  var myPopup = $(this).parents('.img-zoomer-full'),
      prevBox = $(this).parents('.img-zoomer').find('.img-zoomer-box.active').prev().first(),
      myPic = prevBox.find('img').attr('src')
  $('.img-zoomer-box').removeClass('active')
  prevBox.addClass('active')
  isLast(prevBox)
  myPopup.find('img').fadeOut(300, function(){
    myPopup.find('img').attr('src', myPic)
    myPopup.find('img').fadeIn()
  })
})

$('.img-zoomer-next').click(function(){
  var myPopup = $(this).parents('.img-zoomer-full'),
      nextBox = $(this).parents('.img-zoomer').find('.img-zoomer-box.active').next(),
      myPic = nextBox.find('img').attr('src')
  $('.img-zoomer-box').removeClass('active')
  nextBox.addClass('active')
  isLast(nextBox)
  myPopup.find('img').fadeOut(300, function(){
    myPopup.find('img').attr('src', myPic)
    myPopup.find('img').fadeIn()
  })
})
